<template>
  <div>
    <div class="globle_border">
      <div class="search-box">
        <el-date-picker v-model="formInline.time" @change="gettime" type="daterange" range-separator="至"
          start-placeholder="新建开始日期" end-placeholder="新建结束日期" value-format="yyyy-MM-dd"
          style="border-radius: 10px; background: #f2f2f2; margin-bottom: 20px; border: none;width: 280px;">
        </el-date-picker>
        <el-input v-model="packageName" @change="onScreenData" placeholder="请输入进货套餐名称" clearable
          suffix-icon="el-icon-search"></el-input>
        <el-button type="primary" @click="jumpAdd">新建进货套餐</el-button>
      </div>
      <!-- 表格 -->
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540" v-loading="dataLoding">
          <el-table-column prop="number" label="序号" show-overflow-tooltip min-width="60" align="center">
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column prop="packageName" label="进货套餐名称" show-overflow-tooltip min-width="200" align="center">
          </el-table-column>
          <el-table-column prop="productCount" label="商品数量" show-overflow-tooltip min-width="100" align="center">
          </el-table-column>
          <el-table-column prop="createdTime" label="新建时间" show-overflow-tooltip min-width="150" align="center">
          </el-table-column>
          <el-table-column label="操作" header-align="center" show-overflow-tooltip min-width="150" align="center"
            fixed="right">
            <template slot-scope="scope">
              <el-button class="btn" type="primary" plain @click="handEdit(scope.row)">编辑</el-button>
              <el-button class="btn" type="danger" plain @click="handleDelete(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 分页 -->
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
      layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
    </el-pagination>
    <!-- <el-image-viewer v-if="showViewer" :on-close="() => { showViewer = false }" :url-list="urlList"></el-image-viewer> -->
  </div>
</template>
<script>
export default {
  name: "productReview",
  // components: {
  //   'el-image-viewer': () => import('element-ui/packages/image/src/image-viewer')
  // },
  data() {
    return {
      dataLoding: false,
      urlList: [],//图片
      showViewer: false, // 显示查看器、
      packageName: "",//商品包名称
      startTime: '',//开始时间
      endTime: '',//结束时间
      tableData: [],//表格数据
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
      // 弹窗
      // isAdd: false,
      dialogVisible: false,
      typeOptions: [],
      ruleForm: {
        auditMark: '',
      },
      submitLoding: false,
      formInline: {
        time: []
      },//日期区间
    };
  },
  created() {
    this.getProductList();
  },
  methods: {
    getProductList() {
      this.dataLoding = true;
      this.$axios
        .get(this.$api.selectPurchasePackage, {
          params: {
            // bizType: this.bizType || null,//来源
            packageName: this.packageName || null,//商品包名称
            startTime: this.startTime || null,//开始时间
            endTime: this.endTime || null,//结束时间
            page: this.currentPage,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          this.dataLoding = false;
          let result = res.data.result;
          this.tableData = result.list
          this.totalItemsCount = result.totalCount;
        });
    },
    // 时间区间搜索
    gettime() {
      this.startTime = this.formInline.time ? this.formInline.time[0] || "" : "";
      this.endTime = this.formInline.time ? this.formInline.time[1] || "" : "";
      this.onScreenData()
    },
    onCancel() {
      this.$refs["ruleForm"].resetFields();
      this.dialogVisible = false;
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.pageSize = e;
      this.onScreenData();
    },
    // 换页
    handleCurrentChange() {
      this.page += 1;
      this.tableData = [];
      this.getProductList();
    },
    // 筛选
    onScreenData() {
      this.tableData = [];
      this.currentPage = 1;
      this.getProductList();
    },
    // 新建商品包
    jumpAdd() {
      this.$router.push({
        name: "commodityPackage",
      });
    },
    // 删除商品包
    handleDelete(item) {
      const deleteUrl = `${this.$api.deletePurchasePackage}/${item.id}`
      this.$confirm('此操作将删除该商品包信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        this.$axios.post(deleteUrl)
          .then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.onScreenData()
          }).catch((err) => {
            console.log(err);
          });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // 编辑
    handEdit(item) {
      this.$router.push({
        name: "commodityPackage",
        query: {
          id: item.id,
          productCount: item.productCount,
          packageName: item.packageName
        }
      });
    },
    // // 预览图片
    // handleCommand(url) {
    //   this.urlList = url;
    //   this.showViewer = true

    // },
    // 关闭查看器
    // closeViewer() {
    //   this.showViewer = false
    // },
  },
};
</script>
<style lang="scss" scoped>
.search-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;



  .el-input {
    width: 180px;
    margin-left: 10px;
    margin-bottom: 20px;
  }

  .el-button {
    margin-left: 10px;
    margin-bottom: 20px;
  }
}
</style>